import theme from "@theme-ui/preset-base"
import TypoTheme from "./utils/typo-theme"
import { toTheme } from "@theme-ui/typography"
const typoTheme = toTheme(TypoTheme)

const combinedTheme = {
  ...theme,
  ...typoTheme,
}
export default {
  ...combinedTheme,
  colors: {
    ...combinedTheme.colors,
    pale: `#c4c4c4`,
  },
  styles: {
    ...combinedTheme.styles,
  },
  badges: {
    tag: {
      backgroundColor: `#fff`,
      color: `#7d7d7d`,
      border: `1px solid #cacaca`,
      fontWeight: 400,
    },
    toc: {
      backgroundColor: `#fff`,
      color: `#f641ca`,
      border: `none`,
      fontWeight: 400,
      fontSize: `1em`,
    },
  },
  links: {
    primary: {
      color: `#3a8bbb`,
      textDecoration: `none`,
      ":visited": {
        textDecoration: `none`,
        color: `#3a8bbb`,
      },
    },
    blank: {
      color: `#333`,
      textDecoration: `none`,
      ":visited": {
        textDecoration: `none`,
      },
    },
    header: {
      color: `#a3a3a3`,
      textDecoration: `none`,
      ":visited": {
        textDecoration: `none`,
      },
    },
    toc: {
      color: `#333`,
      textDecoration: `none`,
      ":visited": {
        textDecoration: `none`,
      },
      ":hover": {
        color: `#3a8bbb`,
      },
    },
    slug: {
      color: `#c5c5c5`,
      textDecoration: `none`,
      ":visited": {
        textDecoration: `none`,
      },
      ":hover": {
        color: `#888`,
      },
    },
  },
}
